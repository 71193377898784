import "./chart.scss"
import { AreaChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: "January",
        Total: 1200
    },
    {
        name: "February",
        Total: 2100
    },
    {
        name: "March",
        Total: 800
    },
    {
        name: "April",
        Total: 1600
    },
    {
        name: "May",
        Total: 900
    },
    {
        name: "June",
        Total: 1700
    },
  ];

const Chart = ({aspect, title}) => {
  return (
    <div className="chart">
        <div className="title">{title}</div>
        <ResponsiveContainer width="100%" aspect={aspect}>
                <AreaChart
                width={730}
                height={250}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="gray" />
                <Tooltip />
                <Area type="monotone" dataKey="Total" stackId="1" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
            </ResponsiveContainer>
        
    </div>
  )
}

export default Chart
