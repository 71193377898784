import "./login.scss"

const Login = () => {
  return (
    <div className='login'>
      <h1 className="title">Login</h1>
    </div>
  )
}

export default Login
